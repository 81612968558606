/* global Component */
class proxyInput extends Component {

    static name() {
        return "proxyInput";
    }

    static componentName() {
        return "proxyInput";
    }

    getProps() {
        return {
            'def': {
                type: Object,
                required: true
            },
            'value': {
                type: Object,
                default: null
            },
            'defaultValue': {
                type: String,
                default: null
            },
            'custom_css_classes': {
                type: Object,
                default: null
            },
            recordKey: {
                type: String,
                default: null
            },
            pasteFilters: {
                type: Object,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            }
        };
    }

    data() {
        return {
            isSet: false,
        };
    }

    getComputed() {
        return{
            id:function (){
                if(this.getDef.id)
                    return this.getDef.id;
                return this.getUI();
            },
            css_class: function () {
                let default_css = {
                    container :{
                        'form-group': true,
                        "d-none":this.getDef.hidden ? this.getDef.hidden : false,
                        'input-field ': (this.editor != 'checkbox' &&  this.editor != 'vuecomponent' && this.editor != 'matrix'),
                    },
                    label:{
                        "form-label":true
                    },
                    input :{
                        'form-control':true
                    }
                };
                if(this.custom_css_classes){
                    if(this.custom_css_classes.container)
                        Object.assign(default_css.container,this.custom_css_classes.container);
                    if(this.custom_css_classes.label)
                        Object.assign(default_css.label,this.custom_css_classes.label);
                    if(this.custom_css_classes.input)
                        Object.assign(default_css.input,this.custom_css_classes.input);
                }
                return default_css;

            },
            getDef: function () {
                let defaults = {visibility: true, readonly: false, pastewindow: null, fillpastewindow: null};
                Object.assign(defaults, this.def);
                return defaults;
            },
            label: function () {
                if (this.isSet) {
                    return this.tr(this.getDef.label) + "  (a,b)";
                }
                return this.getDef.label;
            },
            editor: function () {
                this.isSet = false;
                if(this.getDef.options || this.getDef.editor == 'combobox')
                    return 'combobox';

                if(this.getDef.linkto)
                    return 'linkto';

                switch (this.getDef.editor || this.getDef.type) {
                    case 'string':
                        return 'string';
                    case 'boolean':
                    case 'checkbox':
                        return 'checkbox';
                    case 'combobox':
                        return 'combobox';
                    case 'period':
                        return 'period';
                    case 'date':
                        return 'date';
                    case 'datepicker':
                        return 'date';
                    case 'set':
                        this.isSet = true;
                        return "string";
                }
                return "string";
            }
        };
    }

    getMethods() {
        return {
            focus() {
                this.$refs.input.focus();
            },
            fpw: function () {
                return this.getDef.fillpastewindow ? this.getDef.fillpastewindow.bind(this.cardcomponent)() : 'NOHABILITADO';
            }
        };
    }

    getTemplate() {
        return `<div key="id" v-bind:class="css_class.container" ref="div" :currentEditor="editor">
                <label :class="css_class.label" v-if="editor != 'period' & editor != 'checkbox' " :for="id">{{tr(label)}}</label>        
                <input :class="css_class.input" :disabled="getDef.readonly || disabled" :label="label" v-if="editor == 'string'"  type="text" :id="id" placeholder="" :value="value || defaultValue" @input="$emit('input',$event.target.value)" ref="input" >
                <input :class="css_class.input" :disabled="getDef.readonly || disabled" v-else-if="editor == 'checkbox'" type="checkbox" :id="id" v-bind:checked="value" @change="$emit('input', $event.target.value)" ref="input" >
                <input :class="css_class.input" :disabled="getDef.readonly || disabled" v-else-if="editor == 'date'" type="date" :id="id"  @change="$emit('input', $event.target.value)" ref="input" >
                <template v-else-if="editor == 'combobox'"> 
                    <select :class="css_class.input" :disabled="getDef.readonly || disabled" :id="id" @change="$emit('input', $refs.input.value)" ref="input" >
                            <template v-for="option in getDef.options">
                                <option :selected="option.value==getDef.default" :value="option.value">{{option.label}}</option>
                            </template>
                    </select>
                </template>
                <template v-else-if="editor == 'linkto'">
                    <selectLinkToComponent  :selected="defaultValue" :disabled="getDef.readonly || disabled" @change="$emit('input', $refs.input.value)" ref="input" :recordKey="recordKey" :table="getDef.linkto" :paste="getDef.linktoPaste" :showField="getDef.linktoShow"  @update-value-full="(data)=> $emit('input',data.value)" > </selectLinkToComponent>
                </template>
                <template v-else-if="editor == 'text'">
                    <textarea :class="css_class.input"  :disabled="getDef.readonly || disabled" type="textarea" :id="id"  @change="$emit('input', $refs.input.value)" ref="input" ></textarea>    
                </template>               
        </div>`;
    }
}

proxyInput.registerComponent();